import React from 'react'

import Hero from '../components/Hero'
import ContentSection from '../components/ContentSection'
import RecentPosts from '../components/RecentPosts'

const PostPage = ({ data, pageContext }) => {
    const { title, date, heroSection, readingTime, recentPosts } = data
    const { minutes } = readingTime
    const { breadcrumb } = pageContext

    return (
        <div className='global-container'>
            <Hero
                data={heroSection}
                breadcrumb={breadcrumb}
                breadcrumbLabel={title}
                date={date}
                readingMinutes={minutes}
            />
            <ContentSection {...data} withSidebar />
            {recentPosts && (
                <RecentPosts
                    title={recentPosts.recentTitle}
                    list={recentPosts.recentList}
                    link={recentPosts.recentButton}
                    withBackground
                />
            )}
        </div>
    )
}

export default PostPage
