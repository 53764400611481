import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import PostPage from '../PostPage'

const post = ({ data, pageContext, location }) => {
    const { nextPage, previousPage, page } = data
    const { uri, featuredImage, seo } = page
    const { title } = seo

    console.log('...POST data start...')
    // console.log(data)
    // console.log(pageContext)
    // console.log(location)
    console.log('...POST data end...')

    return (
        <Layout pageUri={location.pathname}>
            <Seo title={title ? title : page.title} meta={seo} uri={uri} />

            <PostPage data={page} pageContext={pageContext} location={location} />
        </Layout>
    )
}

export const query = graphql`
    query post($id: String!, $nextPage: String, $previousPage: String) {
        page: wpPost(id: { eq: $id }) {
            ...PostContent
        }
        nextPage: wpPost(id: { eq: $nextPage }) {
            title
            uri
        }
        previousPage: wpPost(id: { eq: $previousPage }) {
            title
            uri
        }
    }
`

export default post
